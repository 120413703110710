import React from 'react'
import { Link, graphql } from 'gatsby'

import Bio from '../components/Bio'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import { rhythm } from '../utils/typography'

class ReferralCodes extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Konami Codes"
          keywords={[`codes`, `referral`, `gatsby`, `javascript`, `react`]}
        />
        <Bio />
        <div class="post-content">
          <p>Use these cheat codes at life to get discounts on the services I use and recommend, and I get credit too!</p>

          <h2 id="transportation">Transportation</h2>
          <ul>
            <li><strong><a href="https://join.gojuno.com/b7ZpRGfxIN">Juno</a></strong> - Use my referral code
<code class="highlighter-rouge">b7ZpRGfxIN</code> and get 30% off all rides for two weeks.</li>
            <li><strong><a href="https://ridewithvia.com/?pid=User_invite&amp;c=email">Via</a></strong> - Use my referral code <code class="highlighter-rouge">will7x5</code> to get $10 free when you take your first ride.</li>
            <li><strong><a href="https://www.lyft.com/i/WILL310532">Lyft</a></strong> - Use my referral code <code class="highlighter-rouge">WILL310532</code> to get $15 in ride credit.</li>
            <li><strong><a href="https://www.uber.com/invite/jwrkd">Uber</a></strong> - Use my referral code <code class="highlighter-rouge">jwrkd</code> to get a free Uber ride (up to $5).</li>
          </ul>

          <h2 id="travel">Travel</h2>
          <ul>
            <li><strong><a href="https://txt.htltn.com/LJVXpXIAIN">HotelTonight</a></strong> - Use my referral code <code class="highlighter-rouge">WCHIONG</code> to get $25 off your first booking.</li>
            <li><strong><a href="https://abnb.me/e/oMY9bsqCIN">Airbnb</a></strong> - Use my referral code <code class="highlighter-rouge">oMY9bsqCIN</code> to get $40 off your first stay.</li>
          </ul>

          <h2 id="utilities">Utilities</h2>
          <ul>
            <li><strong><a href="https://g.co/fi/r/36F12K">Project Fi</a></strong> - Use my referral code <code class="highlighter-rouge">36F12K</code> when
signing up to earn $20 bucks on signup.</li>
            <li><strong><a href="https://workflowy.com/invite/38af6eab.emlx">Workflowy</a></strong> - Use my referral code <code class="highlighter-rouge">38af6eab.emlx</code> to get 250 free worklowy items.</li>
            <li><strong><a href="https://gsuite.google.com/landing/partners/referral/trial.html?utm_source=sign-up&amp;utm_medium=referralbutton1&amp;utm_campaign=apps-referral-program&amp;utm_content=79KT7CMH99H9AHY">Google G Suite for Business</a></strong> - Use my referral code <code class="highlighter-rouge">79KT7CMH99H9AHY</code> to get 20% off your first year using the G Suite Business plan- you can get one discount per domain.
    <ul>
                <li><strong><a href="https://gsuite.google.com/landing/partners/referral/trial.html?utm_source=sign-up&amp;utm_medium=referralbutton1&amp;utm_campaign=apps-referral-program&amp;utm_content=79LVKTFU7Q4V9JT">Google G Suite (Basic)</a></strong> - <code class="highlighter-rouge">79LVKTFU7Q4V9JT</code> - This is the alternate link and code specifically for G Suite Basic</li>
              </ul>
            </li>
            <li><strong><a href="https://www.paperspace.com/&amp;R=M0T7SIT">Paperspace</a></strong> - Use my referral code <code class="highlighter-rouge">M0T7SIT</code> to get 10 dollars of credit towards a cloud gaming machine.</li>
          </ul>

          <h2 id="finance">Finance</h2>
          <ul>
            <li><strong><a href="https://share.robinhood.com/willc199">Robinhood</a></strong> - Use my referral code <code class="highlighter-rouge">willc199</code> to get a free mystery stock when you sign up.</li>
            <li><strong><a href="http://share.personalcapital.com/x/kniYxe">Personal Capital</a></strong> - Use my referral code <code class="highlighter-rouge">kniYxe</code> to get a $20 Amazon gift card when you sign up.</li>
          </ul>  
        </div>
      </Layout>
    )
  }
}

export default ReferralCodes

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }`